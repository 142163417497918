export function trimPath (text) {
  const match = text && text.match(/מסכת[^/]+/)
  if (match) text = text.split('מסכת')[1]
  // FIXME: may not work for other sefarim
  return text ? text
    .replace(/(תנ"ך|תורה|נביאים|כתובים)\//g, '')
    .replace(/^ספר /, '')
    .replace(/\/פרק /, ' ')
    .replace(/\/פסוק /, ', ') : ''
}
