// Please don't use the logic below. Instead, set a URL directly with VUE_APP_BACKEND_URL

// This is apparently supposed to use a value such as "corpus010" and extract 010 for calculating a URL
const fullCorpusName = process.env.VUE_APP_CORPUS
let corpusNumber = fullCorpusName ? fullCorpusName.replace(/[^\d]/g, '') : null
if (!(corpusNumber)) corpusNumber = 'no-corpus-number'
// And this is apparently supposed to be "loadbalancer", or perhaps "loadbalancer2".
let DNSbaseForBackend = process.env.VUE_APP_DNS_BASE_FOR_BACKEND
if (!(DNSbaseForBackend)) DNSbaseForBackend = 'no-dns-base-for-backend'
// This says that if process.env.VUE_APP.CORPUS starts with "library" then the URL should start with "dicta" (!),
// otherwise "generic"
let corpusType = 'no-corpus-type'
if (fullCorpusName) if (fullCorpusName.startsWith('library')) {
	corpusType = 'dicta-library'
} else if (fullCorpusName.startsWith('corpus')) corpusType = 'dicta-corpus'
// Calculates a URL such as https://dicta-search-1.loadbalancer.dicta.org.il or https://generic-search-3.loadbalancer2.dicta.org.il
// or https://generic-search-no-corpus-number.no-dns-base-for-backend.dicta.org.il
const complicatedURL = 'https://' + (corpusType === 'dicta-corpus' ? 'dicta' : 'generic')
  + '-search-' + corpusNumber + '.' + DNSbaseForBackend + '.dicta.org.il'

const defaultURL = process.env.VUE_APP_SEARCH_TYPE === 'Bible'
  ? 'https://tanach-search-3-4.loadbalancer.dicta.org.il'
  : (process.env.VUE_APP_SEARCH_TYPE === 'Talmud'
      ? 'https://talmud-search-2.loadbalancer.dicta.org.il' 
      : complicatedURL
    )

export default {
  apiTrafficManager: process.env.VUE_APP_BACKEND_URL || defaultURL,
	corpusType: corpusType
}
